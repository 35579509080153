<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            value=""
                            class="form-control"
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table
                :data="data"
                :columns="fields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import { BFormGroup, BForm, BRow, BCol, BButton } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      data: "",
      startdate: "",
      enddate: "",
      result: "",
      totalCr: 0,
      totalDr: 0,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "voucher_datetime",
          label: "Date",
        },
        {
          field: "ledger_id",
          label: "Name",
        },
        {
          field: "oppledger_id",
          label: "Particular",
        },
        {
          field: "voucher_type",
          label: "Voucher Type",
        },
        {
          field: "debit",
          label: "Dr Amount",
        },
        {
          field: "credit",
          label: "Cr Amount",
        },
        {
          field: "amount",
          label: "Balance",
        },
      ],
    };
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      this.data = [];
      this.totalCr = 0;
      this.totalDr = 0;
      const obj = {
        startdate: this.startdate,
        enddate: this.enddate,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(obj),
        url: `${this.baseApi}/daybook`,
      }).then((response) => {
        this.data = response.data.data;
        const text = {
          voucher_type: "Opening Balance",
          amount: parseFloat(
            this.data.op_amount == null ? 0 : this.data.op_amount
          ),
          id: 1,
        };
        let arr = this.data.ledgervoucher;
        this.data.ledgervoucher = [];
        this.data.ledgervoucher.push(text);

        arr.map((item, index) => {
          this.data.ledgervoucher[index + 1] = item;
          if (parseFloat(this.data.ledgervoucher[index + 1].debit) == 0) {
            this.totalCr += parseFloat(item.credit);
            this.data.ledgervoucher[index + 1].amount =
              this.data.ledgervoucher[index].amount +
              parseFloat(this.data.ledgervoucher[index + 1].credit, 3);
          } else {
            this.totalDr += parseFloat(item.debit);
            this.data.ledgervoucher[index + 1].amount =
              this.data.ledgervoucher[index].amount -
              parseFloat(this.data.ledgervoucher[index + 1].debit, 3);
          }
        });
        const data = {
          credit: this.totalCr,
          debit: this.totalDr,
          amount:
            this.data.ledgervoucher[this.data.ledgervoucher.length - 1].amount,
          voucher_type: "Total",
        };
        this.data.ledgervoucher.push(data);
        this.data = this.data.ledgervoucher;
        this.intervalID = setInterval(this.myCallback, 500);
      });
    },
    myCallback() {
      document.getElementsByTagName("tr")[
        document.getElementsByTagName("tr").length - 1
      ].className = "font-weight-bolder";

      document.getElementsByTagName("tr")[
        document.getElementsByTagName("tr").length / 2 + 1
      ].className = "font-weight-bolder";
      clearInterval(this.intervalID);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
